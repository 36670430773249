import styles from './promotionPopUp.module.css';
import {Swiper, SwiperSlide} from 'swiper/react';
import {promoImages} from '../../constants/data';
import Image from 'next/image';
import {Autoplay, Pagination} from 'swiper/modules';


const PromotionPopUp = () => {
  return (
    <div className={styles.container}>
      <Swiper
        slidesPerView={1}
        loop={true}
        className={styles.swiper}
        loopedSlides={promoImages.length}
        autoplay={{
          delay: 2500,
          pauseOnMouseEnter: true,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Autoplay]}
      >
        {promoImages.map((image, index) => (
          <SwiperSlide
            key={`promotion_${index}`}>
            <Image
              fill={true}
              src={image.src}
              alt={'promo image'} />
          </SwiperSlide>))}
      </Swiper>
    </div>
  );
};

export default PromotionPopUp;
