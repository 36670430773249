import React from 'react';
import Image from 'next/legacy/image';
import Link from 'next/link';

import styles from './Footer.module.css';

const Footer = ({logo, links, socialMedias}) => (
  <div className={styles.app__footer_wrapper}>
    <div className={styles.app__footer_left}>
      <Link href={'/'}>
        <Image src={logo}
          width={318}
          height={149}/>
      </Link>
    </div>
    <div className={styles.app__footer_right}>
      <div className={styles.app__footer_links_wrapper}>
        {links.map((link) =>
          <div key={link.title}>
            <Link
              href={link.url}
            >
              {link.title}
            </Link>
          </div>)
        }
      </div>
      <div className={styles.app__footer_social_wrapper}>
        {Object.entries(socialMedias).map(([key, socialMedia]) =>
          <div key={key}>
            <Link href={socialMedia.url} target={`_blank`}>
              <i className={socialMedia.iconClass}/>
            </Link>
          </div>
        )}
      </div>
    </div>
  </div>
);

export default Footer;
