import Chat from '../../components/Chat';
import React, {useContext, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCommentDots, faXmark} from '@fortawesome/free-solid-svg-icons';
import chatContext from '../../store/chat_context';
import baseContext from '../../store/base_context';
import s from './ButterBot.module.css';
import LocationSelector from '../../components/LocationSelector';
import {Box} from '@chakra-ui/react';

const ButterBot = ({store}) => {
  const chatCtx = useContext(chatContext);
  const baseCtx = useContext(baseContext);
  const [showChat, setShowChat] = useState(false);
  const [loading, setLoading] = useState(true);
  const [chatWidth, setChatWidth] = useState(350);

  useEffect(() => {
    chatCtx.setChatStore(store);
    chatCtx.resetChat();

    setLoading(false);
  }, [store]);


  const toggleChat = () => {
    if (store) {
      setShowChat(!showChat);
    } else {
      baseCtx.updateModal(<LocationSelector />);
    }
  };

  const toggleDoubleWidth = () => {
    setChatWidth(chatWidth === 350 ? 700 : 350);
  };

  return (
    <>
      {!loading && (<div
        className={s.chat_component}>
        {showChat ? (<Box
          width={chatWidth}
          className={s.chat_body}>
          <Chat
            store={chatCtx?.chatStore?.store}
            closeHandler={toggleChat}
            doubled={chatWidth === 700}
            doubleWidthHandler={toggleDoubleWidth}/>
        </Box>) : (
            <>
              <div className={s.chat_popup}>
                {store?.greetingMsg}
              </div>
              <div
                className={s.chat_icon}
                onClick={toggleChat}>
                <FontAwesomeIcon icon={showChat ? faXmark : faCommentDots}/>
              </div>
            </>
        )}
      </div>)
      }
    </>
  );
};

export default ButterBot;
