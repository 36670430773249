import React, {useContext, useEffect} from 'react';
import {parseCookies} from 'nookies';
import {Router, useRouter} from 'next/router';
import NProgress from 'nprogress';

import Navbar from '../../components/Navbar';
import Footer from '../Footer';
import Modal from '../../components/Modal';

import {chatBotStores, defaultSocialMedia, footerLinks, promoImages} from '../../constants/data';
import images from '../../constants/images';
import locationContext from '../../store/location_context';
import baseContext from '../../store/base_context';
import axios from 'axios';
import PromotionPopUp from '../../components/PromotionPopUp';
import {utcToZonedTime} from 'date-fns-tz';
import ButterBot from '../ButterBot';

const BaseFrame = ({children}) => {
  const locationCtx = useContext(locationContext);
  const baseCtx = useContext(baseContext);
  const router = useRouter();

  const storeCode = router?.query?.storeCode;

  const cookies = parseCookies();
  const storeCodeFromCookie = cookies.storeCode;
  const chatStore = chatBotStores.find((store) =>
    store.storeCode === storeCodeFromCookie);

  Router.events.on('routeChangeStart', (...args) => {
    if (!baseCtx.showHeader) {
      baseCtx.updateShowHeader(true);
    }
    baseCtx.updateFooterSocialMedias(defaultSocialMedia);
    NProgress.start();
  });

  Router.events.on('routeChangeComplete', (...args) => {
    NProgress.done();
    window.dataLayer = window.dataLayer || [];
    // eslint-disable-next-line require-jsdoc
    function gtag() {
      // eslint-disable-next-line prefer-rest-params
      dataLayer.push(arguments);
    }

    gtag('set', 'page_path', args[0]);
    gtag('event', 'page_view');

    baseCtx.updateModal(null);
  });
  Router.events.on('routeChangeError', () => {
    NProgress.done();
    baseCtx.updateModal(null);
  });

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    // eslint-disable-next-line require-jsdoc
    function gtag() {
      // eslint-disable-next-line prefer-rest-params
      dataLayer.push(arguments);
    }

    gtag('js', utcToZonedTime(new Date(), 'UTC'));
    gtag('config', 'UA-112672639-1');
  }, []);

  useEffect(() => {
    if (baseCtx.modal) {
      document.body.classList.add('noscroll');
    } else {
      document.body.classList.remove('noscroll');
    }
  }, [baseCtx.modal]);

  useEffect(() => {
    if (!locationCtx.location) {
      const cookies = parseCookies();
      if (cookies.storeCode) {
        const API_V1 = process.env.NEXT_PUBLIC_API_V1;
        const url = API_V1 + `locations/store-code/${cookies.storeCode}`;
        axios(url)
            .then((res) => {
              const location = res.data;
              locationCtx.updateLocation(cookies.storeCode, location);
              console.log('Cookie location fetched.');
            })
            .catch((e) => console.error('Fetch error : -S', e));
      }
    }

    if (router.pathname === '/' && promoImages.length > 0) {
      baseCtx.updateModal(<PromotionPopUp/>);
    }
  }, []);


  useEffect(() => {
    baseCtx.updateStoreCode(storeCode);
  }, [storeCode]);

  return (
    <>
      {baseCtx.showHeader && <Navbar/>}
      {children}
      {baseCtx.showFooter &&
          <Footer
            logo={images.logoWhite}
            links={footerLinks}
            socialMedias={baseCtx.footerSocialMedias}/>}
      {baseCtx.modal &&
                <Modal>
                  {baseCtx.modal}
                </Modal>}
      {router.pathname !== '/[storeCode]' &&
          chatStore &&
          <ButterBot store={chatStore}/>}
    </>
  );
};

export default BaseFrame;
