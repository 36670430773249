import {createContext, useEffect, useState} from 'react';

const CartContent = createContext({
  cartItems: [],
  cartItemsCount: 0,
  cartTotal: 0,
  addCartItem: (item) => {},
  removeCartItem: (item) => {},
  clearCartItem: (item) => {},
  clearCart: () => {},
  updateCart: (cart) => {},
});

// eslint-disable-next-line require-jsdoc
export function CartContentProvider({children}) {
  const [cartItems, setCartItems] = useState([]);
  const [cartItemsCount, setCartItemsCount] = useState(0);
  const [cartTotal, setCartTotal] = useState(0);

  useEffect(() => {
    updateCart();
  }, [cartItems]);

  const updateCart = () => {
    // calculate total and count based on quantity and price of each item
    let total = 0;
    let count = 0;
    cartItems.forEach((item) => {
      total += item.price * item.quantity;
      count += item.quantity;
    });
    setCartTotal(total);
    setCartItemsCount(count);
  };

  const findItemIndex = (item) => {
    return cartItems.findIndex((i) => i.stripePriceId === item.stripePriceId);
  };

  const addCartItemHandler = (item) => {
    // search if item already in cart
    // if so, update quantity, else add new item
    // eslint-disable-next-line max-len
    const itemIndex = findItemIndex(item);
    if (itemIndex === -1) {
      setCartItems((prev) => [...prev, {
        ...item,
        quantity: 1,
      }]);
    } else {
      const newCartItems = [...cartItems];
      newCartItems[itemIndex].quantity += 1;
      setCartItems(newCartItems);
    }
  };

  const removeCartItemHandler = (item) => {
    // search if item already in cart
    // if so, update quantity, if quantity is 0, remove item
    // eslint-disable-next-line max-len
    const itemIndex = findItemIndex(item);
    if (itemIndex !== -1) {
      const newCartItems = [...cartItems];
      newCartItems[itemIndex].quantity -= 1;
      if (newCartItems[itemIndex].quantity === 0) {
        newCartItems.splice(itemIndex, 1);
      }
      setCartItems(newCartItems);
    }
  };

  const clearCartItemHandler = (item) => {
    // remove the whole item
    // eslint-disable-next-line max-len
    const itemIndex = findItemIndex(item);
    if (itemIndex !== -1) {
      const newCartItems = [...cartItems];
      newCartItems.splice(itemIndex, 1);
      setCartItems(newCartItems);
    }
  };

  const clearCartHandler = () => {
    setCartItems([]);
  };

  const updateCartHandler = (cart) => {
    setCartItems(cart);
  };

  const context = {
    cartItems,
    cartItemsCount,
    cartTotal,
    addCartItem: addCartItemHandler,
    removeCartItem: removeCartItemHandler,
    clearCartItem: clearCartItemHandler,
    clearCart: clearCartHandler,
    updateCart: updateCartHandler,
  };

  return (
    <CartContent.Provider value={context}>
      {children}
    </CartContent.Provider>
  );
}

export default CartContent;
