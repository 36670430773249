// eslint-disable-next-line max-len
import {
  AbsoluteCenter,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay, Heading, HStack, IconButton,
  Table, Tbody, Td, Text, Tr, VStack,
} from '@chakra-ui/react';
import cartContext from '../../../store/cart_context';
import {useContext} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
import {loadStripe} from '@stripe/stripe-js';
import axios from 'axios';
import {DeleteIcon} from '@chakra-ui/icons';

// eslint-disable-next-line no-unused-vars
const stripePromise = loadStripe(
    process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY
);

const CartDrawer = ({isOpen, onClose}) => {
  const cartCtx = useContext(cartContext);

  const checkoutHandler = async () => {
    if (cartCtx.cartItemsCount > 0) {
      const API_V1 = process.env.NEXT_PUBLIC_API_V1;

      const res = await axios.post( `${API_V1}payment/order-checkout`, {
        cartItems: cartCtx.cartItems,
      });
      const {redirectUrl, id} = res.data;
      const stripe = await stripePromise;
      const {error} = await stripe.redirectToCheckout({
        sessionId: id,
      });
      if (error) {
        window.location.href = redirectUrl;
      }
    }
  };

  const cartContent = (<DrawerContent>
    <DrawerCloseButton />
    <DrawerBody>
      <Heading size={'md'}>My Cart</Heading>
      <Table>
        <Tbody>
          {cartCtx.cartItems.map((item) => (
            <Tr key={item.productId}>
              <Td
                padding={1}>
                <IconButton
                  variant={'ghost'}
                  size={'xs'}
                  aria-label={'remove'}
                  colorScheme={'red'}
                  onClick={() => cartCtx.clearCartItem(item)}
                  icon={<DeleteIcon />}/></Td>
              <Td>{item.productName}</Td>
              <Td
                padding={2}>
                <VStack>
                  <Text>
                          ${item.price / 100}
                  </Text>
                  <HStack
                    border={'1px solid #e5e7eb'}
                    borderRadius={15}
                    paddingX={2}>
                    <FontAwesomeIcon
                      fontSize={13}
                      cursor={'pointer'}
                      onClick={() => cartCtx.removeCartItem(item)}
                      icon={faMinus} />
                    <Text>{item.quantity}</Text>
                    <FontAwesomeIcon
                      fontSize={13}
                      cursor={'pointer'}
                      onClick={() => cartCtx.addCartItem(item)}
                      icon={faPlus} />
                  </HStack>
                </VStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </DrawerBody>
    <DrawerFooter>
      <VStack>
        <Table>
          <Tbody>
            <Tr>
              <Td>SubTotal</Td>
              <Td>${cartCtx.cartTotal / 100}</Td>
            </Tr>
          </Tbody>
        </Table>
        <button
          onClick={checkoutHandler}
          className={'custom__button'}
          type={'submit'}>
              Checkout
        </button>
      </VStack>
    </DrawerFooter>
  </DrawerContent>);

  const emptyCartContent = (<DrawerContent>
    <DrawerCloseButton />
    <DrawerBody>
      <Heading size={'md'}>My Cart</Heading>
      <AbsoluteCenter><Text>Cart is empty</Text></AbsoluteCenter>
    </DrawerBody>
  </DrawerContent>);

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}>
      <DrawerOverlay />
      {cartCtx.cartItemsCount > 0 ? cartContent : emptyCartContent}
    </Drawer>
  );
};

export default CartDrawer;
