import styles from './modal.module.css';
import baseContext from '../../store/base_context';
import {useContext} from 'react';


const Modal = ({children}) => {
  const baseCtx = useContext(baseContext);

  const overlayClickHandler = (event) => {
    if (event.target.id === 'overlay') {
      baseCtx.clearModal();
    }
  };

  return (
    <div
      id={`overlay`}
      className={styles.overlay}
      onClick={overlayClickHandler}>
      {children}
    </div>
  );
};

export default Modal;
