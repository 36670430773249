import locationContext from '../../store/location_context';
import baseContext from '../../store/base_context';
import {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import Spinner from '../Spinner';

import styles from './locationSelector.module.css';
import LocationPopUp from '../LocationPopUp';
import {parseLocationInfo} from '../../helpers/utils';

const LocationSelector = () => {
  const locationCtx = useContext(locationContext);
  const baseCtx = useContext(baseContext);

  const [selectedState, setSelectedState] = useState('');
  const [splitNum, setSplitNum] = useState(0);

  const calculateSplitNum = () => {
    const locationBorder = document
        .querySelector('[class*=locationSelector_right_container]');
    setSplitNum(Math.ceil(locationBorder.clientWidth / 300));
  };

  useEffect(() => {
    if (!locationCtx.locationList) {
      const API_V1 = process.env.NEXT_PUBLIC_API_V1;
      const url = API_V1 + 'locations';
      axios(url)
          .then((res) => {
            const locationList = res.data;
            if (locationList.length > 0) {
              setSelectedState(locationList[0]['administrativeArea']);
            }
            const parsedLocationList = locationList.map((location) => {
              const parsedInfo = parseLocationInfo(location);
              return {
                ...location,
                ...parsedInfo,
              };
            });
            locationCtx.updateLocationList(parsedLocationList);
          })
          .catch((e) => {
            console.error('Error: ', e);
          });
    }
  }, []);

  useEffect(() => {
    if (locationCtx.locationList) {
      calculateSplitNum();
    }

    if (!selectedState && locationCtx.locationList) {
      const tempLocation = locationCtx.location ?
          locationCtx.location :
          locationCtx.locationList[0];

      setSelectedState(tempLocation.administrativeArea);
    }
  }, [selectedState]);

  const locations = locationCtx.locationList ? locationCtx.locationList : [];
  const stateList = [...new Set(locations.map((location) =>
    location.administrativeArea))];

  const filteredLocations = locations.filter((location) =>
    location.administrativeArea === selectedState);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>
          <div className={styles.title_text}>
            Set Your Location
          </div>
          <div>
            <i
              className={`icon-sc-close 
                                  ${styles.close_icon}
                                  custom__clickable_link`}
              onClick={() => {
                baseCtx.clearModal();
              }}/>
          </div>
        </div>
        {locationCtx.locationList ?
            <div className={styles.content_wrapper}>
              <div className={styles.left_container}>
                {stateList.map((state) => (
                  <div
                    key={state}
                    className={`custom__clickable_link 
                            ${styles.left_option}
                            ${state === selectedState ?
                            styles.left_option_selected : ''}`}
                    onClick={() => setSelectedState(state)}>
                    {state}
                  </div>
                ))}
              </div>
              <div className={styles.right_container}>
                {selectedState !== '' &&
                    <>
                      <div className={styles.right_title}>
                        <span>Shaking Crab In {selectedState}</span>
                      </div>
                      <div className={styles.right_locations_container}>
                        {
                          filteredLocations.map((location) => (
                            <LocationPopUp
                              key={location.PK}
                              location={location}/>
                          ))
                        }
                        {filteredLocations.length < splitNum &&
                            [...Array(
                                splitNum - filteredLocations.length
                            )
                                .keys()]
                                .map((index) => {
                                  return <div key={index}/>;
                                })
                        }
                      </div>
                    </>
                }
              </div>
            </div> :
            <Spinner/>}
      </div>
    </>
  );
};

export default LocationSelector;
