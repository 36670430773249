import React, {useContext} from 'react';
import Link from 'next/link';

import styles from './locationPopUp.module.css';
import locationContext from '../../store/location_context';
import baseContext from '../../store/base_context';
import {setCookie} from 'nookies';


const LocationPopUp = ({
  location,
}) => {
  const locationCtx = useContext(locationContext);
  const baseCtx = useContext(baseContext);

  const clickHandler = () => {
    setCookie(null, 'storeCode', location.storeCode, {
      maxAge: 30 * 24 * 60 * 60,
      path: '/',
    });

    locationCtx.updateLocation(location.storeCode, location);
    baseCtx.clearModal();
  };

  return (
    <Link
      href={`/locations/${location.storeCode}#location_info`}
      passHref
      legacyBehavior>
      <div
        className={`custom__clickable_link ${styles.location_container}`}
        onClick={clickHandler}>
        <p className={styles.location_title}>{location.title}</p>
        {location.address !== null &&
            location.address !== '' &&
            <p className={styles.location_address}>
              {/* eslint-disable-next-line max-len */}
              <i className={`icon-sc-location2 ${styles.location_icon}`}/>
              {location.address}
            </p>}
        {location.phoneNum !== null &&
            location.phoneNum !== '' &&
            <p className={styles.location_phone}>
              <i className={`icon-sc-telephone ${styles.location_icon}`}/>
              {location.phoneNum}</p>}
      </div>
    </Link>
  );
};

export default LocationPopUp;
