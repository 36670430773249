import {createContext, useState} from 'react';
import {defaultSocialMedia} from '../constants/data';

const BaseContext = createContext({
  showHeader: null,
  showFooter: null,
  modal: null,
  footerSocialMedias: null,
  storeCode: null,
  updateShowHeader(ifShowHeader) {},
  updateShowFooter(ifShowFooter) {},
  updateModal(modalContent) {},
  updateStoreCode(storeCode) {},
  clearModal() {},
  updateFooterSocialMedias(socialMedias) {},
});


/**
 * Base Context Provider
 * @param {JSX.Element} children
 * @return {JSX.Element}
 * @constructor
 */
export function BaseContextProvider({children}) {
  const [activeShowHeader, setActiveShowHeader] = useState(true);
  const [activeShowFooter, setActiveShowFooter] = useState(true);
  const [activeModal, setActiveModal] = useState(null);
  const [activeFooterSocialMedias, setActiveFooterSocialMedias] = useState(
      defaultSocialMedia);
  const [activeStoreCode, setActiveStoreCode] = useState(null);

  /**
   * Update showHeader
   * @param {boolean} ifShowHeader
   */
  function showHeaderHandler(ifShowHeader) {
    setActiveShowHeader(ifShowHeader);
  }

  /**
   * Update showFooter
   * @param {boolean} ifShowFooter
   */
  function showFooterHandler(ifShowFooter) {
    setActiveShowFooter(ifShowFooter);
  }

  /**
   * Update modal
   * @param {component} modal
   */
  function updateModalHandler(modal) {
    setActiveModal(modal);
  }

  /**
   * Clear current modal
   */
  function clearModalHandler() {
    setActiveModal(null);
  }

  /**
   * Update social medias
   * @param {object} socialMedias
   */
  function footerSocialMediasHandler(socialMedias) {
    setActiveFooterSocialMedias({
      ...activeFooterSocialMedias,
      ...socialMedias,
    });
  }

  // eslint-disable-next-line require-jsdoc
  function updateStoreCode(storeCode) {
    setActiveStoreCode(storeCode);
  }

  const context = {
    showHeader: activeShowHeader,
    showFooter: activeShowFooter,
    modal: activeModal,
    footerSocialMedias: activeFooterSocialMedias,
    storeCode: activeStoreCode,
    updateShowHeader: showHeaderHandler,
    updateShowFooter: showFooterHandler,
    updateModal: updateModalHandler,
    clearModal: clearModalHandler,
    updateFooterSocialMedias: footerSocialMediasHandler,
    updateStoreCode: updateStoreCode,
  };

  return (
    <BaseContext.Provider value={context}>
      {children}
    </BaseContext.Provider>
  );
}

export default BaseContext;
