import {createContext, useState} from 'react';

const LocationContext = createContext({
  locationCode: '',
  location: null,
  locationList: null,
  isSelectorVisible: null,
  setIsSelectorVisible(isVisible) {},
  updateLocation(newLocationCode, newLocation) {},
  updateLocationList(newLocationList) {},
});


/**
 * Location Context Provider
 * @param {JSX.Element} children
 * @return {JSX.Element}
 * @constructor
 */
export function LocationContextProvider({children}) {
  const [activeLocationCode, setActiveLocationCode] = useState('');
  const [activeLocation, setActiveLocation] = useState(null);
  const [activeLocationList, setActiveLocationList] = useState(null);
  const [isSelectorVisible, setIsSelectorVisible] = useState(false);

  /**
     * Update location code and location info in context
     * @param {string} newLocationCode
     * @param {object} newLocation
     */
  function updateLocationHandler(newLocationCode, newLocation) {
    setActiveLocationCode(newLocationCode);
    setActiveLocation(newLocation);
  }

  /**
   * Update location list in context
   * @param {array} newLocationList
   */
  function updateLocationListHandler(newLocationList) {
    setActiveLocationList(newLocationList);
  }

  /**
   * Determine location selector shows or not
   * @param {boolean} isVisible
   */
  function setSelectorVisibleHandler(isVisible) {
    setIsSelectorVisible(isVisible);
  }

  const context = {
    locationCode: activeLocationCode,
    location: activeLocation,
    locationList: activeLocationList,
    isSelectorVisible: isSelectorVisible,
    updateLocation: updateLocationHandler,
    updateLocationList: updateLocationListHandler,
    setIsSelectorVisible: setSelectorVisibleHandler,
  };

  return (
    <LocationContext.Provider value={context}>
      {children}
    </LocationContext.Provider>
  );
}

export default LocationContext;
