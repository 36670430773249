
import styles from './sectionTitle.module.css';

const SectionTitle = ({title, subtitle}) => (
  <div>
    <div className={styles.app__section_title}>
      <div>
        <i className={`icon-sc-claws`} >
          <span className={`path1 ${styles.icon_claw_path1}`}/>
          <span className={`path2 ${styles.icon_claw_path2}`}/>
          <span className={`path3 ${styles.icon_claw_path3}`}/>
        </i>
      </div>
      <div><span className={styles.app__section_title_text}>{title}</span></div>
      <div className={`custom__reverse`}>
        <i className={`icon-sc-claws`} >
          <span className={`path1 ${styles.icon_claw_path1}`}/>
          <span className={`path2 ${styles.icon_claw_path2}`}/>
          <span className={`path3 ${styles.icon_claw_path3}`}/>
        </i>
      </div>
    </div>
    <div>
      <span
        className={styles.app__section_subtitle_text}>
        {subtitle}
      </span>
    </div>
  </div>
);

export default SectionTitle;
