import Link from 'next/link';
import SectionTitle from '../components/SectionTitle';

import styles from './data.module.css';

export const footerLinks = [
  {
    title: 'FRANCHISING',
    url: '/franchising',
  },
  {
    title: 'CONTACT',
    url: 'https://forms.gle/nWyqwt2zMJd7qZ6d8',
  },
  {
    title: 'OUR STORY',
    url: '/story',
  },
  {
    title: 'PRESS',
    url: '/press',
  },
  {
    title: 'CAREERS',
    url: 'https://app.joinhomebase.com/cmp/shaking-crab',
  },
  {
    title: 'SHAKE AROUND THE WORLD',
    url: '/shake-around-the-world',
  },
];

export const highlightPress = [
  {
    imgUrl: 'https://tools.shakingcrab.com/storage/images/4a/43affa8efb55d4a9bd269cfd283985.jpg',
    // eslint-disable-next-line max-len
    title: 'Eater Boston: Shaking Crab Will Rattle and Roll Into a New Neighborhood',
    link: 'https://boston.eater.com/2016/10/27/13433986/shaking-crab-opening-new-restaurant-quincy',
    time: 'October 27, 2016',
  },
];

export const pressInfo = [
  {
    imgUrl: 'https://tools.shakingcrab.com/storage/images/a5/1a8861208a793fb93b4ee93e6a8826.jpeg',
    title: 'A Cajun seafood place is shaking things up in Newton',
    link: 'https://www.bostonglobe.com/metro/regionals/west/2016/01/22/shaking-crab-brings-flavors-new-orleans-newton/0v1IecBaZNlZUc8btcjvUJ/story.html',
    time: 'January 22, 2016',
  },
  {
    imgUrl: 'https://tools.shakingcrab.com/storage/images/d7/6e07420cf677ee6cfef4682efe90e2.png',
    title: '5 Must-Try New Southern Restaurants in Boston',
    link: 'https://www.zagat.com/b/5-must-try-new-southern-restaurants-in-boston',
    time: 'April 13, 2016',
  },
  {
    imgUrl: 'https://tools.shakingcrab.com/storage/images/d7/6e07420cf677ee6cfef4682efe90e2.png',
    title: '10 Must-Try New Seafood Dishes in Boston',
    link: 'https://www.zagat.com/b/10-must-try-new-seafood-dishes-in-boston',
    time: 'April 27, 2016',
  },
  {
    imgUrl: 'https://tools.shakingcrab.com/storage/images/5c/a6d0a1989336bd48352a9289a11b96.jpeg',
    title: 'Phantom Gourmet: Shaking Crab In Newton',
    link: 'https://boston.cbslocal.com/2016/09/13/phantom-gourmet-shaking-crab-in-newton/',
    time: 'September 13, 2016',
  },
  {
    imgUrl: 'https://tools.shakingcrab.com/storage/images/32/5f2ccb36b70f9861558729e132b12d.png',
    // eslint-disable-next-line max-len
    title: 'Shaking Crab Bursts Into Quincy With Bags Full of Cajun-Style Seafood',
    link: 'https://boston.eater.com/2017/4/13/15282526/shaking-crab-quincy-gallery',
    time: 'April 13, 2017',
  },
  {
    imgUrl: 'https://tools.shakingcrab.com/storage/images/5e/fe305b3f555462376f6f4228f689e0.jpeg',
    // eslint-disable-next-line max-len
    title: 'A buttery, spicy, messy seafood joint is expanding across Greater Boston',
    link: 'https://www.boston.com/culture/restaurants/2017/06/08/a-buttery-spicy-messy-seafood-joint-is-expanding-across-greater-boston',
    time: 'April 27, 2016',
  },
];

export const contactUrl = {
  googleFormUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSfcAt5L_6oEjgBqndeQvYBqpi3jowUjvBeugcLKjb44TdnyGQ/formResponse',
};

export const defaultSocialMedia = {
  facebook: {
    iconClass: 'icon-sc-facebook',
    url: 'https://www.facebook.com/shakingcrabofficial',
  },
  instagram: {
    iconClass: 'icon-sc-instagram',
    url:
        'https://www.instagram.com/shakingcrab',
  },
  youtube: {
    iconClass: 'icon-sc-youtube',
    url:
        'https://www.youtube.com/c/ShakingCrab',
  },
  twitter: {
    iconClass: 'icon-sc-twitter',
    url:
        'https://twitter.com/shakingcrab',
  },
};

export const locations = [
  'newlondonct',
  'williamsburg',
  'quincy',
  'brookline',
  'newton',
  'clifton',
  'longisland',
  'providence',
  'mtkisco',
  'brooklyn',
  'flushing',
  'bostoncommon',
  'bronx',
  'statenisland',
  'goffstown',
  'harrisonnj',
  'foxborough',
  'mi-livonia-now-open',
  'rainbowlv',
  'hellskitchen',
  'unionnj',
  'uws',
  'nanuet',
  'springfield',
  'parkslope',
];

export const promotion = {
  title: (
    <div className={styles.title}>
      <SectionTitle
        title={'ALL YOU CAN EAT NOW AVAILABLE'}
        subtitle={'MON - WED AT PARTICIPATING LOCATIONS'}/>
    </div>
  ),
  content: (
    <div
      className={styles.content}>
      <div>
        <p
          className={`custom__purple-button ${styles.region}`}>New England</p>
        <p>
          <Link href={`/locations/newton#location_info`}>
            Newton<br/>(12p-4p Sat-Sun)
          </Link>
        </p>
        <p>
          <Link href={`/locations/goffstown#location_info`}>
            Goffstown<br/>(4p-9p Wed-Thu)
          </Link>
        </p>
        <p>
          <Link href={`/locations/brookline#location_info`}>
            Brookline<br/>(4p-8p Mon-Wed)
          </Link>
        </p>
        <p>
          <Link href={`/locations/foxborough#location_info`}>
            Foxborough<br/>(4p-9:30p Mon-Wed)
          </Link>
        </p>
        <p>
          <Link href={`/locations/providence#location_info`}>
            Providence<br/>(2p-9:30p Mon-Wed)
          </Link>
        </p>
        <p>
          <Link href={`/locations/quincy#location_info`}>
            Quincy<br/>(4p-9:30p Mon-Wed)
          </Link>
        </p>
        <p>
          <Link href={`/locations/springfield#location_info`}>
            Springfield<br/>(daily)
          </Link>
        </p>
      </div>
      <div>
        <p className={`custom__purple-button ${styles.region}`}>
          NYC
        </p>
        <p>
          <Link href={`/locations/uws#location_info`}>
            Upper West Side<br/>(4p-8p Mon-Wed)
          </Link>
        </p>
        <p>
          <Link href={`/locations/parkslope#location_info`}>
            Park Slope<br/>(4p-8p Mon-Wed)
          </Link>
        </p>
        <p>
          <Link href={`/locations/statenisland#location_info`}>
            Staten Island<br/>(2p-8p daily, 1p-5p Fri-Sat)
          </Link>
        </p>
        <p>
          <Link href={`/locations/williamsburg#location_info`}>
            Williamsburg<br/>(4p-8:30p)
          </Link>
        </p>
        <p>
          <Link href={`/locations/bronx#location_info`}>
            Bronx<br/>(opening-5p daily)
          </Link>
        </p>
      </div>
      <div>
        <p className={`custom__purple-button ${styles.region}`}>
          Las Vegas
        </p>
        <p>
          <Link href={`/locations/rainbowlv#location_info`}>
            Rainbow (daily)
          </Link>
        </p>
        <p>
          <Link href={`/locations/spring-mountain-lv#location_info`}>
            Spring Mountain (daily)
          </Link>
        </p>
      </div>
    </div>),
  images: [
    'https://cdn.shakingcrab.com/image/IMG_6843.jpg',
    'https://cdn.shakingcrab.com/image/IMG_6884.jpg',
  ],
};

export const promoImages = [
  // {
  //   src: 'https://cdn.shakingcrab.com/website/images/shaking+special+poster.jpg',
  //   alt: 'Shaking Crab',
  //   url: '/ayce-new-england',
  // },
  // {
  //   src: 'https://cdn.shakingcrab.com/website/images/AYCE+promo+new+england+-+4.11.jpg',
  //   alt: 'Shaking Crab',
  //   url: '/ayce-new-england',
  // },
  // {
  //   src: 'https://cdn.shakingcrab.com/website/images/AYCE+promo+new+york+%26+vegas.jpg',
  //   alt: 'Shaking Crab',
  //   url: '/ayce-nyc-n-lv',
  // },
];

export const ayceInfo = {
  areas: {
    newEngland: [
      {
        locationName: 'Newton',
        locationUrl: '/locations/newton#location_info',
        locationHours: '12p-4p Sat-Sun',
      },
      {
        locationName: 'Goffstown',
        locationUrl: '/locations/goffstown#location_info',
        locationHours: '4p-9p Wed-Thu',
      },
      {
        locationName: 'Brookline',
        locationUrl: '/locations/brookline#location_info',
        locationHours: '4p-8p Mon-Wed',
      },
      {
        locationName: 'Foxborough',
        locationUrl: '/locations/foxborough#location_info',
        locationHours: '4p-9:30p Mon-Wed',
      },
      {
        locationName: 'Providence',
        locationUrl: '/locations/providence#location_info',
        locationHours: '2p-9:30p Mon-Wed',
      },
      {
        locationName: 'Quincy',
        locationUrl: '/locations/quincy#location_info',
        locationHours: '4p-9:30p Mon-Wed',
      },
      {
        locationName: 'Springfield',
        locationUrl: '/locations/springfield#location_info',
        locationHours: 'daily',
      },
    ],
    nyc: {

    },
    lasVegas: {

    },
  },
};

export const chatBotQuestionExamples = [
  {
    question: 'Make an order',
  },
  // {
  //   question: 'I want to make reservation.',
  // },
  {
    question: 'General Questions',
  },
];
export const testChatBotQuestionExamples = [
  {
    question: 'Make an order',
  },
  // {
  //   question: 'I want to make reservation.',
  // },
  {
    question: 'General Questions',
  },
];
export const chatBotStores = [
  {
    name: 'General',
    store: 'skcb',
    storeCode: 'not_applicable',
  },
  {
    name: 'Boston Common (MA)',
    store: 'skcb_boston_common',
    storeCode: 'not_applicable',
  },
  {
    name: 'Bronx (NYC)',
    store: 'skcb_bronx',
    storeCode: 'not_applicable',
  },
  {
    name: 'Brookline (MA)',
    store: 'skcb_brookline',
    storeCode: 'not_applicable',
  },
  {
    name: 'Foxborough (MA)',
    store: 'skcb_foxborough',
    storeCode: 'not_applicable',
  },
  {
    name: 'Goffstown (NH)',
    store: 'skcb_goffstown',
    storeCode: 'not_applicable',
  },
  {
    name: 'Hell\'s Kitchen (NYC)',
    store: 'skcb_hells_kitchen',
    storeCode: 'not_applicable',
  },
  {
    name: 'Newton (MA)',
    store: 'skcb_newton',
    storeCode: 'newton',
    // eslint-disable-next-line max-len
    greetingMsg: 'Welcome to Shaking Crab Newton, I’m Butter, how may I help you today?',
  },
  {
    name: 'Park Slope (NYC)',
    store: 'skcb_park_slope',
    storeCode: 'not_applicable',
  },
  {
    name: 'Providence (RI)',
    store: 'skcb_providence',
    storeCode: 'not_applicable',
  },
  {
    name: 'Quincy (MA)',
    store: 'skcb_quincy',
    storeCode: 'not_applicable',
  },
  {
    name: 'Staten Island (NYC)',
    store: 'skcb_staten_island',
    storeCode: 'not_applicable',
  },
  {
    name: 'Upper West Side (NYC)',
    store: 'skcb_upper_west_side',
    storeCode: 'not_applicable',
  },
  {
    name: 'Williamsburg (NYC)',
    store: 'skcb_williamsburg',
    storeCode: 'not_applicable',
  },
];

export const userIntents = {
  DISH_RECOMMENDATION: 'dish recommendation',
  REQUIREMENT_DESCRIPTION: 'requirement description',
  ORDER_CONFIRMATION: 'order confirmation',
  COMPLAINT: 'complaint',
  OTHER: 'other',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  footerLinks,
  contactUrl,
};
