import axios from 'axios';
import {addDays, format, startOfWeek} from 'date-fns';
import {utcToZonedTime} from 'date-fns-tz';
import zipcodeToTimezone from 'zipcode-to-timezone';

export const parseLocationInfo = (location) => {
  if (!location) {
    return {
      address: '',
      phoneNum: '',
      openHours: {},
    };
  }

  const address = 'storefrontAddress' in location ?
      location.storefrontAddress.addressLines.join(' ') +
      ', ' +
      location.storefrontAddress.locality +
      ', ' +
      location.storefrontAddress.administrativeArea +
      (location.storefrontAddress.postalCode ?
          ' ' + location.storefrontAddress.postalCode : '') :
      '';

  const postalCode = location?.storefrontAddress?.postalCode;
  const tz = zipcodeToTimezone.lookup(postalCode.slice(0, 5)) || 'UTC';

  const phoneNum = 'phoneNumbers' in location &&
  'primaryPhone' in location.phoneNumbers ?
      location.phoneNumbers.primaryPhone :
      '';

  const openHours = [];

  const buttons = [];

  if (location?.reserveUrl) {
    buttons.push({
      text: 'Reservation',
      src: location.reserveUrl,
    });
  }

  if (location?.pickUpUrl) {
    buttons.push({
      text: 'Pick Up/Delivery',
      src: location.pickUpUrl,
    });
  }

  if (location?.links) {
    buttons.push(...location.links.map((link) => ({
      text: link.label,
      src: link.url,
    })));
  }

  const currentLocalDatetime = utcToZonedTime(new Date(), tz) || new Date();
  const monday = startOfWeek(currentLocalDatetime, {weekStartsOn: 1});

  if ('regularHours' in location) {
    const periods = location.regularHours.periods;
    for (let i = 0; i < 7; i++) {
      const curDay = addDays(monday, i);
      const curWeekDayLabel = format(curDay, 'eeee');
      const openPeriods = periods.filter((period) =>
        period.openDay.toUpperCase() === curWeekDayLabel.toUpperCase());
      const hours = openPeriods.length > 0 ?
          parseHourPeriods(openPeriods) :
          [];
      openHours.push({
        weekday: curWeekDayLabel,
        isOpen: hours.length > 0,
        date: format(curDay, 'yyyy-MM-dd'),
        hours: hours,
      });
    }
  } else {
    for (let i = 0; i < 7; i++) {
      const curDay = addDays(monday, i);
      const curWeekDayLabel = format(curDay, 'eeee');
      openHours.push({
        weekday: curWeekDayLabel,
        date: format(curDay, 'yyyy-MM-dd'),
        isOpen: false,
      });
    }
  }

  const openHoursWithSpecialHours = openHours.map((openHour) => {
    const matchedHourPeriods = 'specialHours' in location ?
     location.specialHours.specialHourPeriods.filter((hourPeriod) => {
       const periodDate = format(
           new Date(
               hourPeriod.startDate.year,
               hourPeriod.startDate.month - 1,
               hourPeriod.startDate.day),
           'yyyy-MM-dd'
       );
       return periodDate === openHour.date;
     }) :
    false;

    if (matchedHourPeriods &&
        matchedHourPeriods.length > 0) {
      const hours = parseHourPeriods(matchedHourPeriods);
      return {
        weekday: openHour.weekday,
        date: openHour.date,
        isOpen: hours.length > 0,
        hours: hours,
      };
    } else {
      return openHour;
    }
  });

  const overdayHoursCombined = openHoursWithSpecialHours.map(
      (weekdayHours, index) => {
        const nextIndex = index + 1 === openHoursWithSpecialHours.length ?
            0 : index + 1;
        const previousIndex = index - 1 === -1 ?
            openHoursWithSpecialHours.length - 1 : index - 1;

        const currentDayHours = weekdayHours;
        const nextDayHours = openHoursWithSpecialHours[nextIndex];
        const previousDayHours = openHoursWithSpecialHours[previousIndex];

        let updatedHours = currentDayHours.hours;

        const currentDayRightOverHour = currentDayHours?.hours &&
            currentDayHours.hours.find(
                (hour) => hour.closeTime === '24:00');
        const nextDayLeftOverHour = nextDayHours?.hours &&
            nextDayHours.hours.find(
                (hour) => hour.openTime === '00:00');
        const previousDayRightOverHour = previousDayHours?.hours &&
            previousDayHours.hours.find(
                (hour) => hour.closeTime === '24:00');
        const currentDayLeftoverHour = currentDayHours?.hours &&
            currentDayHours.hours.find(
                (hour) => hour.openTime === '00:00');

        if (currentDayRightOverHour && nextDayLeftOverHour) {
          updatedHours = updatedHours.filter(
              (hour) => hour.closeTime !== '24:00');
          updatedHours.push({
            openTime: currentDayRightOverHour.openTime,
            openTime12Hour: currentDayRightOverHour.openTime12Hour,
            closeTime: nextDayLeftOverHour.closeTime,
            closeTime12Hour: nextDayLeftOverHour.closeTime12Hour,
          });
        }

        if (previousDayRightOverHour && currentDayLeftoverHour) {
          updatedHours = updatedHours.filter(
              (hour) => hour.openTime !== '00:00');
        }

        return {
          weekday: currentDayHours.weekday,
          date: currentDayHours.date,
          isOpen: currentDayHours.isOpen,
          hours: updatedHours,
        };
      });

  return {
    address,
    phoneNum,
    openHours: overdayHoursCombined,
    buttons,
    timezone: tz,
  };
};

const parseHourPeriods = (hourPeriods) => {
  const hours = [];
  for (const hourPeriod of hourPeriods) {
    if ('closed' in hourPeriod && hourPeriod.closed) {
      continue;
    }

    try {
      const openTime = hourPeriod.openTime?.hours ?
          hourPeriod.openTime?.hours.toString() +
          ':' +
          ((hourPeriod?.openTime?.minutes &&
                  hourPeriod?.openTime?.minutes.toString()) ||
              '00') :
          '00:00';
      const openTime12Hour = hourPeriod.openTime?.hours ?
          format(
              new Date('1970-01-01 ' + openTime), 'hh:mm a') :
          '12:00 AM';
      const closeTime = hourPeriod?.closeTime?.hours.toString() +
          ':' +
          ((hourPeriod?.closeTime?.minutes &&
                  hourPeriod?.closeTime?.minutes.toString()) ||
              '00');
      const closeTime12Hour = format(
          new Date('1970-01-01 ' + closeTime), 'hh:mm a');
      hours.push({
        openTime,
        closeTime,
        openTime12Hour,
        closeTime12Hour,
      });
    } catch (e) {
      console.error('Cannot parse following time period: ' +
          JSON.stringify(hourPeriod));
    }
  }

  return hours;
};

export const swrFetcher = async (url) => {
  const res = await axios(url);

  if (res.status < 200 || res.status > 299) {
    const error = new Error('An error occurred while fetching the data.');
    error.info = res.data;
    error.status = res.status;
    throw error;
  }

  return res.data;
};
