import {
  ChatContainer,
  MainContainer,
  Message,
  MessageInput,
  MessageList, TypingIndicator,
  Button, InputToolbox,
} from '@chatscope/chat-ui-kit-react';
import {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import {format} from 'date-fns';
import {chatBotQuestionExamples} from '../../constants/data';
import s from './Chat.module.css';
import chatContext from '../../store/chat_context';
import {useRouter} from 'next/router';
import {Box, HStack, IconButton} from '@chakra-ui/react';
import logo from '../../public/assets/images/LOGO_GOLD.png';
import Image from 'next/image';
import {CloseIcon} from '@chakra-ui/icons';
import {RiContractLeftRightLine, RiExpandLeftRightLine} from 'react-icons/ri';

const Chat = ({
  store = 'skcb',
  closeHandler,
  doubled,
  doubleWidthHandler}) => {
  const router = useRouter();
  const chatCtx = useContext(chatContext);

  const [inputValue, setInputValue] = useState('');
  const chatAPI = process.env.NEXT_PUBLIC_CHAT_API;

  const messages = [
    ...chatCtx.userMessages,
    ...chatCtx.systemMessages,
  ].sort((a, b) => {
    return new Date(a.sentTime) - new Date(b.sentTime);
  });

  const chatSession = (usrMsgs) => {
    return {
      chat_id: chatCtx.chatId,
      store: store,
      sys_history: chatCtx.systemMessages.map((m) => m.message),
      usr_history: usrMsgs.map((m) => m.message),
    };
  };

  const initMessages = async (store) => {
    const chatIdUrl = chatAPI + `chat_id`;
    const chatIdResp = await axios(chatIdUrl);
    chatCtx.setChatId(chatIdResp.data?.id);

    const greetingUrl = chatAPI + `greeting/${store}`;
    const greetingResp = await axios(greetingUrl);
    const greetingMessage = {
      message: greetingResp.data?.sys_answer,
      sender: 'system',
      sentTime: format(Date.now(), 'PPpp'),
      direction: 'incoming',
    };
    // const api = await axios(process.env.NEXT_PUBLIC_CHAT_API + `api`);

    chatCtx.setSystemMessages([greetingMessage]);
    chatCtx.setUserMessages([]);
    chatCtx.setLoading(false);
    // setAPI(api.data?.value);
  };

  useEffect(() => {
    if (!chatCtx.chatId) {
      initMessages(store);
    }
  }, [store]);

  // useEffect(() => {
  //   const lastMessage = document.querySelector(
  //       `.cs-message-list__typing-indicator-container`);
  //   const y = lastMessage?.getBoundingClientRect().top + window.scrollY;
  //   window.scroll({
  //     top: y,
  //     behavior: 'smooth',
  //   });
  // }, [messages]);


  const handleSend = async (message) => {
    const updatedUserMessages = [...chatCtx.userMessages,
      {
        message: message,
        sender: 'me',
        sentTime: format(Date.now(), 'PPpp'),
        direction: 'outgoing',
      }];
    // Add message to the list
    chatCtx.setUserMessages(updatedUserMessages);
    chatCtx.setSystemReplying(true);
    setInputValue('');

    // Send message to the server
    // ...
    const sendUrl = chatAPI + `chat`;
    const replyResp = await axios.post(sendUrl,
        chatSession(updatedUserMessages));
    const reply = {
      message: replyResp?.data?.sys_answer ||
          'Sorry, Butter didn\'t get that, please try again.',
      sender: 'system',
      sentTime: format(Date.now(), 'PPpp'),
      direction: 'incoming',
    };
    chatCtx.setSystemMessages([...chatCtx.systemMessages, reply]);
    chatCtx.setSystemReplying(false);
  };

  const suggestingClickHandler = (message) => {
    if (message === 'Make an order') {
      const storeCode = chatCtx.chatStore.storeCode;
      router.push(`/locations/${storeCode}/menu`);
    }
    handleSend(message);
  };

  const messageList = messages.map((message, index) => (
    <Message
      key={index}
      model={message}
    />));

  const inputSuggestions = !chatCtx.loading && (
    <MessageList.Content
      className={s.example_questions}>
      {chatBotQuestionExamples.map((example, index) => {
        return (
          <div
            key={index}>
            <button
              className={s.question_card}
              onClick={suggestingClickHandler.bind(null, example.question)}>
              <span
                className={s.question_text}>
                &quot;{example.question}&quot;&#8594;
              </span>
            </button>
          </div>
        );
      })}
    </MessageList.Content>
  );

  const handleCheckout = async () => {
    const orderURL = chatAPI + `orders/atomic-checkout`;
    chatCtx.setSystemReplying(true);
    const resp = await axios.post(orderURL, chatSession(chatCtx.userMessages));
    chatCtx.setSystemReplying(false);
    const reURL = resp.data?.data?.redirect_url;
    console.log('redirect url: %s', reURL);
    if (reURL !== undefined) {
      window.open(reURL);
    }
  };

  const btnStyle = {
    padding: '0px 10px',
  };
  const checkoutBtn = (
    <Button style={btnStyle} border onClick={handleCheckout}>Checkout</Button>
  );

  return (
    <Box
      height={'100%'}>
      <HStack
        padding={2}
        borderTopStartRadius={'5px'}
        borderTopEndRadius={'5px'}
        background={'#998062'}>
        <Box
          flex={1}>
          <Image src={logo} alt={'logo'} width={100} height={100} />
        </Box>
        <HStack>
          <IconButton
            aria-label={'double chat width'}
            variant={'ghost'}
            icon={doubled ?
                <RiContractLeftRightLine
                  size={25}
                  color={'#FFD360'}/> :
              <RiExpandLeftRightLine
                size={25}
                color={'#FFD360'}/>}
            onClick={doubleWidthHandler} />
          <IconButton
            aria-label={'close chat'}
            variant={'ghost'}
            icon={<CloseIcon color={'#FFD360'}/>}
            onClick={closeHandler} />
        </HStack>
      </HStack>
      <Box
        height={'500px'}>
        <MainContainer
          responsive={true}>
          <ChatContainer>
            <MessageList
              loading={chatCtx.loading}
              autoScrollToBottom={true}
              typingIndicator={
                  chatCtx.systemReplying ?
                      <TypingIndicator
                        content={'Butter is typing'} /> :
                      null}>
              {chatCtx.userMessages.length > 0 ?
                  messageList :
                  inputSuggestions}
            </MessageList>
            {chatCtx.userMessages.length > 0 &&
                <MessageInput
                  activateAfterChange={true}
                  value={inputValue}
                  onChange={(message) => setInputValue(message)}
                  onSend={handleSend}
                  placeholder="Type message here"
                  sendDisabled={inputValue.length === 0}
                  attachButton={false} />
            }
            {chatCtx.userMessages.length > 0 &&
                <InputToolbox>
                  {checkoutBtn}
                </InputToolbox>}
          </ChatContainer>
        </MainContainer>
      </Box>
    </Box>
  );
};

export default Chat;
