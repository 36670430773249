import {createContext, useState} from 'react';

const ChatContext = createContext({
  chatStore: null,
  systemMessages: [],
  userMessages: [],
  chatId: null,
  loading: true,
  systemReplying: false,
  setChatStore(store) {},
  setSystemMessages(messages) {},
  setUserMessages(messages) {},
  setChatId(id) {},
  setLoading(loading) {},
  setSystemReplying(replying) {},
  resetChat() {},
});

// eslint-disable-next-line require-jsdoc
export function ChatContextProvider({children}) {
  const [activeChatStore, setActiveChatStore] = useState('');
  const [activeSystemMessages, setActiveSystemMessages] = useState([]);
  const [activeUserMessages, setActiveUserMessages] = useState([]);
  const [activeChatId, setActiveChatId] = useState(null);
  const [activeLoading, setActiveLoading] = useState(true);
  const [activeSystemReplying, setActiveSystemReplying] = useState(false);

  const updateChatStoreHandler = (store) => {
    setActiveChatStore(store);
  };

  const updateSystemMessagesHandler = (messages) => {
    setActiveSystemMessages(messages);
  };

  const updateUserMessagesHandler = (messages) => {
    setActiveUserMessages(messages);
  };

  const updateChatIdHandler = (id) => {
    setActiveChatId(id);
  };

  const updateLoadingHandler = (loading) => {
    setActiveLoading(loading);
  };

  const updateSystemReplyingHandler = (replying) => {
    setActiveSystemReplying(replying);
  };

  const resetChatHandler = () => {
    setActiveSystemMessages([]);
    setActiveUserMessages([]);
    setActiveChatId(null);
    setActiveLoading(true);
    setActiveSystemReplying(false);
  };

  const context = {
    chatStore: activeChatStore,
    systemMessages: activeSystemMessages,
    userMessages: activeUserMessages,
    chatId: activeChatId,
    loading: activeLoading,
    systemReplying: activeSystemReplying,
    setChatStore: updateChatStoreHandler,
    setSystemMessages: updateSystemMessagesHandler,
    setUserMessages: updateUserMessagesHandler,
    setChatId: updateChatIdHandler,
    setLoading: updateLoadingHandler,
    setSystemReplying: updateSystemReplyingHandler,
    resetChat: resetChatHandler,
  };

  return (
    <ChatContext.Provider value={context}>
      {children}
    </ChatContext.Provider>
  );
}

export default ChatContext;
