import shakingTime from '../public/assets/images/ShakingTime.png';
import logoGold from '../public/assets/images/LOGO_GOLD.png';
import logoWhite from '../public/assets/images/logo_white.png';
import theTotalCatch from '../public/assets/images/TheTotalCatch.png';
import seafoodGalore from '../public/assets/images/SeafoodGalore.png';
import theMaineCourse from '../public/assets/images/TheMaineCourse.png';
import theJumboShaker from '../public/assets/images/Jumbo-JumboShaker.png';
import willamsburg from '../public/assets/images/williamsburg.png';
import harrison from '../public/assets/images/harrison.png';
import newlondon from '../public/assets/images/newlondon.png';
import gallery1 from '../public/assets/images/location_gallary_1.jpg';
import gallery2 from '../public/assets/images/location_gallary_2.jpeg';
import gallery3 from '../public/assets/images/location_gallary_3.jpeg';
import post1 from '../public/assets/images/post1.jpg';
import post2 from '../public/assets/images/post2.jpg';
import post3 from '../public/assets/images/post3.jpg';
import post4 from '../public/assets/images/post4.jpg';
import post5 from '../public/assets/images/post5.jpg';
import post6 from '../public/assets/images/post6.jpg';
import post7 from '../public/assets/images/post7.jpg';
import post8 from '../public/assets/images/post8.jpg';
import veg from '../public/assets/icons/vegtarian.png';
import gf from '../public/assets/icons/gf.png';


export default {
  shakingTime,
  logoGold,
  logoWhite,
  theTotalCatch,
  seafoodGalore,
  theMaineCourse,
  theJumboShaker,
  willamsburg,
  harrison,
  newlondon,
  gallery: [gallery1, gallery2, gallery3, gallery2, gallery3],
  shakeGallery: [post1, post2, post3, post4, post5, post6, post7, post8],
  veg,
  gf,
};
