import React, {useContext, useState} from 'react';
import Image from 'next/legacy/image';
import Link from 'next/link';
import {GiHamburgerMenu} from 'react-icons/gi';
import {MdOutlineRestaurantMenu} from 'react-icons/md';

import styles from './Navbar.module.css';
import locationContext from '../../store/location_context';
import baseContext from '../../store/base_context';
import cartContext from '../../store/cart_context';
import {useRouter} from 'next/router';
import LocationSelector from '../LocationSelector';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCartShopping} from '@fortawesome/free-solid-svg-icons';
import {HStack, useDisclosure} from '@chakra-ui/react';
import CartDrawer from './CartDrawer';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const locationCtx = useContext(locationContext);
  const baseCtx = useContext(baseContext);
  const cartCtx = useContext(cartContext);
  const router = useRouter();
  const {isOpen, onOpen, onClose} = useDisclosure();

  const hasReservation =
      !locationCtx.location || locationCtx.location.reserveUrl;

  /**
   * Order and reserve button handler
   * @param {string} field
   */
  function buttonClickHandler(field) {
    if (locationCtx.location) {
      if (field === 'order') {
        // eslint-disable-next-line max-len
        router.push(`/locations/${locationCtx.location.storeCode}#location_info`);
      } else {
        router.push(locationCtx.location[field]);
      }
    } else {
      baseCtx.updateModal(<LocationSelector />);
    }
  }

  return (
    <>
      <nav className={styles.app__navbar}>
        <div className={styles.app__navbar_left}>
          <Link href='/' passHref legacyBehavior>
            <div className={styles.app__navbar_logo}>
              <Image
                className='custom__clickable_link'
                src={`https://tools.shakingcrab.com/storage/images/82/c08a754b36e809f4897e53b7ae1865.png`}
                alt="sc__logo"
                width={120}
                height={`50%`}
                objectFit={`contain`}/>
            </div>
          </Link>
          <ul className={styles.app__navbar_links}>
            <li className="p__opensans">
              <Link href='/menu' passHref legacyBehavior>
                <span>Menu</span>
              </Link>
            </li>
            <li className="p__opensans">
              <Link href='/locations' passHref legacyBehavior>
                <span>Location</span>
              </Link>
            </li>
            <li className="p__opensans">
              <Link href='/store' passHref legacyBehavior>
                <span>Online Shop</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className={styles.app__navbar_order}>
          {hasReservation &&
              <button
                className='custom__button'
                onClick={buttonClickHandler.bind(0, 'reserveUrl')}>
                Reservation
              </button>}
          <button
            className='custom__button'
            onClick={buttonClickHandler.bind(0, 'order')}>
            Order Now
          </button>
          <div className={styles.app__navbar_small_screen}>
            <GiHamburgerMenu color="#E1B236" fontSize={27}
              onClick={() => setToggleMenu(true)} />
            {toggleMenu && (
              <div className={`${styles.app__navbar_small_screen_overlay}
          flex__center slide-bottom`}>
                <MdOutlineRestaurantMenu
                  fontSize={27}
                  className={styles.overlay_close}
                  onClick={() => setToggleMenu(false)} />
                <ul className={styles.app__navbar_small_screen_links}>
                  <li>
                    <Link href='/menu' passHref legacyBehavior>
                      <span onClick={() => setToggleMenu(false)}>
                    Menu</span>
                    </Link>
                  </li>
                  <li>
                    <Link href='/locations' passHref legacyBehavior>
                      <span onClick={() => setToggleMenu(false)}>
                    Location</span>
                    </Link>
                  </li>
                  <li>
                    <Link href='/store' passHref legacyBehavior>
                      <span onClick={() => setToggleMenu(false)}>
                    Online Shop</span>
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <HStack
            cursor={'pointer'}
            alignItems={'start'}
            onClick={onOpen}>
            <FontAwesomeIcon
              color={'white'}
              fontSize={27}
              icon={faCartShopping}/>
            <span
              className={`${styles.badge} ${styles.cart_count}`}
            >{cartCtx.cartItemsCount}</span>
          </HStack>
        </div>
      </nav>
      <CartDrawer
        isOpen={isOpen}
        onClose={onClose} />
    </>
  );
};

export default Navbar;
