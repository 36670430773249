import {Oval} from 'react-loader-spinner';

import styles from './spinner.module.css';

const Spinner = () => (
  <div className={styles.app__spinner_wrap}>
    <Oval
      heigth="100"
      width="100"
      color="#E1B236"
      secondaryColor=""
      ariaLabel="loading-indicator"
    />
  </div>
);

export default Spinner;
