import Head from 'next/head';
import 'nprogress/nprogress.css';
import 'swiper/css/bundle';

import '../styles/App.css';
import '../styles/index.css';
import '../styles/icons.css';
import '../styles/selector_yellow.css';
import '../styles/selector_mobile.css';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import BaseFrame from '../container/BaseFrame';
import {LocationContextProvider} from '../store/location_context';
import {BaseContextProvider} from '../store/base_context';
import {library} from '@fortawesome/fontawesome-svg-core';
import {config} from '@fortawesome/fontawesome-svg-core';
import {faCommentDots} from '@fortawesome/free-solid-svg-icons';
import {ChatContextProvider} from '../store/chat_context';
import {ChakraProvider} from '@chakra-ui/react';
import {CartContentProvider} from '../store/cart_context';
import Script from 'next/script';

config.autoAddCss = false;

library.add(faCommentDots);

/**
 * MyApp function
 * @param {component} Component
 * @param {object[]} pageProps
 * @return {JSX.Element}
 * @constructor
 */
function MyApp({Component, pageProps}) {
  return (
    <ChakraProvider>
      <BaseContextProvider>
        <LocationContextProvider>
          <ChatContextProvider>
            <CartContentProvider>
              <BaseFrame>
                <Head>
                  <title>Shaking Crab</title>
                  <meta name="description"
                    /* eslint-disable-next-line max-len */
                    content="BIBS UP, PHONES DOWN. IT'S #SHAKINGTIME Dive headfirst into a bag of steaming hot seafood shaken in rich butter, an abundance of garlic, and a signature blend of Cajun spices."/>
                  {/* eslint-disable-next-line max-len */}
                  <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no, minimum-scale=1.0, maximum-scale=1.0"/>
                  <meta name="apple-mobile-web-app-capable" content="yes"/>
                  {/* eslint-disable-next-line max-len */}
                  <meta name="apple-mobile-web-app-status-bar-style" content="black"/>
                  <meta property="og:site_name" content="Shaking Crab"/>
                  <meta property="og:title" content="Shaking Crab"/>
                  <meta property="og:url" content="https://www.shakingcrab.com"/>
                  <meta property="og:type" content="website"/>
                  {/* eslint-disable-next-line max-len */}
                  <meta property="og:description" content="BIBS UP, PHONES DOWN. IT'S #SHAKINGTIME Dive headfirst into a bag of steaming hot seafood shaken in rich butter, an abundance of garlic, and a signature blend of Cajun spices."/>
                  <meta property="og:image"
                    content="https://tools.shakingcrab.com/storage/images/93/7a0aa7675911bd66f373ecb800a08c.jpg"/>
                  <meta property="og:image:width" content="1500"/>
                  <meta property="og:image:height" content="758"/>
                  <meta itemProp="name" content="Shaking Crab"/>
                  <meta itemProp="url" content="https://www.shakingcrab.com"/>
                  {/* eslint-disable-next-line max-len */}
                  <meta itemProp="description" content="BIBS UP, PHONES DOWN. IT'S #SHAKINGTIME Dive headfirst into a bag of steaming hot seafood shaken in rich butter, an abundance of garlic, and a signature blend of Cajun spices."/>
                  <meta itemProp="thumbnailUrl"
                    content="https://tools.shakingcrab.com/storage/images/93/7a0aa7675911bd66f373ecb800a08c.jpg"/>
                  <meta itemProp="image"
                    content="https://tools.shakingcrab.com/storage/images/93/7a0aa7675911bd66f373ecb800a08c.jpg"/>
                  <meta name="twitter:title" content="Shaking Crab"/>
                  <meta name="twitter:image"
                    content="https://tools.shakingcrab.com/storage/images/93/7a0aa7675911bd66f373ecb800a08c.jpg"/>
                  <meta name="twitter:url" content="https://www.shakingcrab.com"/>
                  <meta name="twitter:card" content="summary"/>
                  {/* eslint-disable-next-line max-len */}
                  <meta name="twitter:description" content="BIBS UP, PHONES DOWN. IT'S #SHAKINGTIME Dive headfirst into a bag of steaming hot seafood shaken in rich butter, an abundance of garlic, and a signature blend of Cajun spices."/>
                  <meta name="facebook-domain-verification"
                    content="32719onzbvbgits39ouzwexh9q48ng"/>
                  <meta name="google-adsense-account"
                    content="ca-pub-1554720281564504"/>
                </Head>
                <Component {...pageProps} />
              </BaseFrame>
              <Script src={'/doordashButton.js'}/>
            </CartContentProvider>
          </ChatContextProvider>
        </LocationContextProvider>
      </BaseContextProvider>
    </ChakraProvider>
  );
}

export default MyApp;
